<template>
  <div>
    <div class="section section__company">
      <div class="section__wrapper">
        <div class="title-with-icon title-with-icon_menu mb-5">
          <div class="title-with-icon__icon title-with-icon__icon_back"></div>
          <div class="title-with-icon__title">
            <a href="/sveden">Сведения об образовательной организации</a>
          </div>
          <div class="title-with-icon__delimeter">/</div>
          <div class="title-with-icon__title">{{ name }}</div>
        </div>
        <div class="my-5 d-block" v-if="text" v-html="text"></div>
        <div class="my-5 d-block">
          <a class="history-back" href="/sveden">Вернуться</a>
        </div>
        <div class="section__subsection_blue section__company-nav">
          <div class="section__subsection-wrapper">
            <company-navigation />
          </div>
        </div>
      </div>
    </div>
    <page-footer/>
  </div>
</template>

<script>
import PageFooter from "../../components/PageFooter";
import ApiService from "../../../services/api.service";
import CompanyNavigation from "../../components/CompanyNavigation";
export default {
  name: "InfoEdu",
  components: {CompanyNavigation, PageFooter},
  data() {
    return {
      blocks: null,
      text: null,
      name: null,
    }
  },
  mounted() {
    ApiService.get('infocategories/obrazovatelnye-standarty-i-trebovaniya').then(res => {
      this.blocks = res.data.info_blocks
      this.text = res.data.description
      this.name = res.data.name
      this.$parent.init()
    })
  }
}
</script>

<style lang="scss">
  .section__company {
    h4 {
      font-size: 3.2rem;
      font-weight: 700;
      margin-top: 7rem!important;
      &.no-margin {
        margin: -40px 0 20px!important;
      }
    }
    .content_18 {
      a {
        color: #ed1b2f;
        text-decoration: underline;
      }
    }
  }
</style>